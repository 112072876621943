/**
 * Created by BETALOS on 23/11/2016.
 */
(function () {

    'use strict';

    module.exports = interfacingService;

    const {Observable} = require('rxjs');
    const ORDER_DIALOG = require('../dialogs/order-form-dialog');
    const IMPORTED_EXAMS_DIALOG = require('../dialogs/import-exam-dialog');

    interfacingService.$inject = [
        "$q", "$http", "mnWebSocket", "$mdToast", "$translate", "$mdDialog", "patientService", "$timeout"
    ];

    function interfacingService($q, $http, mnWebSocket, $mdToast, $translate, $mdDialog, patientService, $timeout) {
        let self = this;

        self.getDevices = getDevices;
        self.getDeviceDetail = getDeviceDetail;
        self.handleDeviceConfig = handleDeviceConfig;
        self.removeDeviceConfig = removeDeviceConfig;
        self.deviceConfigUpdates = deviceConfigUpdates;

        self.sendOrder = sendOrder;
        self.createOrder = createOrder;
        self.getPatientOrder = getPatientOrder;

        self.getExamInstance = getExamInstance;
        self.importInterfacingInstance = importInterfacingInstance;
        self.importLastInterfacingInstance = importLastInterfacingInstance;
        self.handleInterfacingInstance = handleInterfacingInstance;

        function getDevices() {
            const deferred = $q.defer();
            const url = "/api/interfacing-device/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getDeviceDetail(device) {
            const deferred = $q.defer();
            const url = `/api/interfacing-device-full/${device}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleDeviceConfig(device, driver) {
            let driverCopy = _.cloneDeep(driver);

            driverCopy["default_config_id"] = _.has("default_config_id") ? driverCopy["default_config_id"] : driverCopy['id'];

            _.unset(driverCopy, 'id');

            const query = {device: device, driver: driverCopy};

            return mnWebSocket.call('interfacing.InterfacingDevice.handle_configuration', query);
        }

        function removeDeviceConfig(device, driver) {
            let driverCopy = _.assign({is_delete: true}, _.pick(driver, 'id_config'));
            let query = {device: device, driver: driverCopy};

            return mnWebSocket.call('interfacing.InterfacingDevice.remove_configuration', query);
        }

        function deviceConfigUpdates(device) {
            return new Observable(observer => {
                mnWebSocket.sub('interfacing.InterfacingDevice.config_notify', data => {
                    const simpleToast = $mdToast.simple()
                        .textContent($translate.instant('device_config_success', {device: data.device_name}))
                        .position("bottom right")
                        .hideDelay(2000);

                    $mdToast.show(simpleToast);

                    if (data.id === device) observer.next(data);
                });

                return {
                    unsubscribe: () => {
                        mnWebSocket.unsub('interfacing.InterfacingDevice.config_notify');
                        observer.complete();
                    }
                }
            });
        }

        function createOrder(patient, ev) {
            mnWebSocket.call('interfacing.InterfacingDevice.check_device_configs', {})
                .then(success, error);

            function success(devices) {
                $mdDialog.show(_.assign({}, ORDER_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        config: null, devices, patient,
                    }
                }));
            }

            function error() {
                const simpleToast = $mdToast.simple()
                    .textContent($translate.instant('exam_has_no_driver_associated'))
                    .position("bottom right")
                    .hideDelay(2000);

                $mdToast.show(simpleToast);
            }

        }

        function getPatientOrder(patient) {
            return mnWebSocket.call('patient.PatientMinimal.order_data', {patient});
        }

        function sendOrder(devices, order) {
            return mnWebSocket.call('interfacing.InterfacingDevice.send_order', {
                devices, order
            });
        }


        function getExamInstance(instance) {
            const deferred = $q.defer();
            const url = `/api/received-data/${instance}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }


        function importInterfacingInstance(patient, preSave, context, ev, resolve = false) {
            const deferred = $q.defer();

            patientService.getMinimalPatient(patient)
                .then(done);

            function done(data) {
                const query = {
                    exam_files: {$ne: []},
                    patient_last_name: data['last_name'],
                    patient_first_name: data['first_name'],
                };

                $mdDialog.show(_.assign({}, IMPORTED_EXAMS_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        query,
                        patient,
                        resolve,
                        preSave,
                        context,
                        fullName: data['full_name']
                    }
                })).then(instance => {
                    console.log(instance);
                    deferred.resolve(instance)
                }, deferred.reject);
            }

            return deferred.promise;
        }

        function importLastInterfacingInstance(patient, preSave, context, resolve = false) {
            const deferred = $q.defer();

            mnWebSocket.call('interfacing.ReceivedData.patient_last_received_data', {patient})
                .then(success, error);

            function success(instance) {
                if (instance) {
                    if (resolve) deferred.resolve(instance);
                    else if (!_.isNil(preSave)) preSave().then(() => startSave(instance));
                    else startSave(instance);
                }

                else error();
            }

            function startSave(instance) {
                handleInterfacingInstance(patient, instance, context).then(deferred.resolve, error);
            }

            function error() {
                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('dcm_patient_has_no_study'))
                    .position("bottom left")
                    .hideDelay(2500);

                $timeout(() => {
                    $mdToast.show(simpleToast);
                    deferred.reject();
                }, 100);
            }

            return deferred.promise;
        }

        function handleInterfacingInstance(patient, instance, context) {
            return mnWebSocket.call('interfacing.InterfacingDevice.handle_received_data_files', {
                patient, context, instance: instance.id
            })
        }
    }

})();
