/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const ERRORS_VISIT_DIALOG = require('visit/dialogs/errors-visit-dialog');

    class VisitListCtrl {
        constructor($state, paymentService, billingService, visitService, careSheetService, $mdDialog, $translate) {
            this.$state = $state;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.visitService = visitService;
            this.paymentService = paymentService;
            this.billingService = billingService;
            this.careSheetService = careSheetService;

            this.filter = {is_deleted: {"$ne": true}};
            this.refresh = this.refresh || _.noop;
        }

        static get $inject() {
            return [
                "$state", "paymentService", "billingService", "visitService", "careSheetService", "$mdDialog",
                "$translate"
            ];
        }

        $onInit() {
            this.visitService.visitSubLinks.subscribe(data => {
                let linkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);

                this.actions = {
                    single: [
                        {
                            icon: 'mdi-pencil',
                            label: 'edition',
                            resource: 'visit',
                            action: 'update',
                            behavior: 'disable',
                            method: (v, e) => this.goToVisit(v, e, linkConfig.link)
                        },
                        {
                            icon: 'mdi-currency-usd',
                            label: 'pay_visit',
                            resource: 'payment',
                            action: 'create',
                            behavior: 'disable',
                            disabled: "item.total_amount <= 0",
                            method: (v, e) => this.payVisit(v, e)
                        },
                        {
                            icon: 'mdi-receipt',
                            label: 'billing_visit',
                            resource: 'billing-invoice',
                            action: 'create',
                            behavior: 'disable',
                            method: (v, e) => this.billingService.billingVisit(v, e)
                        },
                        {
                            icon: 'mdi-file-document',
                            label: 'quotation',
                            resource: 'quotation',
                            action: 'create',
                            behavior: 'disable',
                            method: (v, e) => this.billingService.GenerateVisitQuotation(v, e)
                        }, {
                            icon: 'mdi-folder-plus',
                            label: 'visit_care_sheet',
                            resource: 'visit',
                            action: 'create',
                            behavior: 'disable',
                            disabled: "item.total_amount <= 0",
                            method: (v, e) => {
                                this.careSheetService.visitCareSheet(v, e)
                            }
                        }
                    ],
                    multiple: [
                        {
                            icon: 'mdi-receipt',
                            label: 'billing_visit',
                            resource: 'billing-invoice',
                            action: 'create',
                            behavior: 'disable',
                            method: (v, e) => this.billingService.billingVisit(v, e)
                        },
                        {
                            icon: 'mdi-folder-plus',
                            label: 'patient_care_sheet',
                            resource: 'visit',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: v => this.multipleCareSheets(v)
                        },
                        {
                            icon: 'mdi-delete',
                            label: 'visit_delete',
                            resource: 'visit',
                            action: 'delete',
                            behavior: 'remove',
                            method: (v, e) => this.remove(v, e)
                        },
                        {
                            icon: 'mdi-eye',
                            label: 'visit_hide',
                            resource: 'visit',
                            specialPower: true,
                            method: (v, e) => this.privatize(v, e)
                        },
                        {
                            icon: 'mdi-eye-off',
                            label: 'visit_unhide',
                            resource: 'visit',
                            specialPower: true,
                            method: (v, e) => this.unprivatize(v, e)
                        }
                    ]
                };

            });
        }

        goToVisit(visit, ev, link) {
            const state = link ? link : 'app.visit.consultation';
            this.$state.go(state, {visitId: visit.id, pId: visit['patId']});
        }

        payVisit(visit, ev) {
            ev.stopPropagation();

            this.visitService.getVisit(visit.id)
                .then(visit => {
                    this.paymentService.payVisit(ev, visit);
                });
        }

        remove(list, ev) {
            const confirm = this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant("visit_remove_confirm_title"))
                .htmlContent(this.translate.instant("visit_remove_confirm_text"));

            this.dialog.show(confirm).then(() => {
                this.promise = this.visitService.removeVisits(list)
                    .then(err => this.removeErrors(err, ev));
            });
        }

        removeErrors(errs, ev) {
            this.refresh({}, false, true);

            if (!_.isEmpty(errs)) {
                this.dialog.show(_.assign({}, ERRORS_VISIT_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        visits: errs,
                        icon: "mdi-playlist-remove",
                        title: this.translate.instant("visit_removing_title"),
                        content: this.translate.instant("visit_removing_err"),
                        subContent: this.translate.instant("visit_removing_err_2")
                    }
                }));
            }
        }

        privatize(list) {
            this.promise = this.visitService.privatizeVisits(list)
                .then(() => this.refresh({}, true, true));
        }

        unprivatize(list) {
            this.promise = this.visitService.unprivatizeVisits(list)
                .then(() => this.refresh({}, true, true));
        }

        multipleCareSheets(list, ev) {
            const create = (validate) => {
                this.promise = this.careSheetService.multipleCareSheets(list, validate)
                    .then((errs, ev) => this.careSheetErrors(errs, ev));
            };

            const confirm = this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant("visit_bulk_caresheet_validation_title"))
                .htmlContent(this.translate.instant("visit_bulk_caresheet_validation_text"));

            this.dialog.show(confirm).then(() => create(true), () => create(false));
        }

        careSheetErrors(errs, ev) {
            this.refresh({}, false, true);

            if (!_.isEmpty(errs)) {
                this.dialog.show(_.assign({}, ERRORS_VISIT_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        visits: errs,
                        icon: "mdi-playlist-check",
                        title: this.translate.instant("visit_bulk_caresheet_validation_title"),
                        content: this.translate.instant("visit_bulk_caresheet_err"),
                        subContent: this.translate.instant("visit_bulk_caresheet_err_2")
                    }
                }));
            }
        }

    }

    module.exports = VisitListCtrl;

})();
