/**
 * Created by Hp on 23/03/2017. [edited by BETALOS 17/10/2019]
 */
(function () {

    'use strict';

    const FAMILY_DIALOG = require('../dialogs/family-dialog');

    class FamilyCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;

            this.items = this.items || [];
            this.parent = this.parent || null;
            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.internalValue = this.value;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        addFamily(ev) {
            const dialog = _.assign({}, FAMILY_DIALOG, {
                locals: {
                    families: this.items,
                    parent_family: this.parent,
                    showParent_family: false,

                },
                multiple: true,
                targetEvent: ev,
                focusOnOpen: false,
                clickOutsideToClose: true,
            });

            this.dialog.show(dialog).then(data => {
                this.value = data;
                this.items.push(data);
                this.internalValueChanged();
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, $attrs) {
        const label = $attrs['mnLabel'];
        const filter = $attrs['mnFilter'] ? "| filter:" + $attrs['mnFilter'] : "";

        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const isRequired = $elem.is("[required]") ? "required" : "";
        const ngDisabled = !_.isUndefined($attrs['ngDisabled']) ? 'ng-disabled="vm.disabled"' : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        return `
            <md-input-container>
                <label translate-once="${label}"></label>
                <md-select ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id'}" ng-change="vm.internalValueChanged()" 
                    style="min-width: 10px" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled}>
                    <md-option ng-repeat="item in vm.items ${filter} track by item.id"  ng-value="item" ng-bind="item.name"></md-option>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button class="md-icon-button" ng-click="vm.addFamily($event)" aria-label="add" ${ngDisabled} ${isDisabled} tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ng-if="vm.family && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>      
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            parent: "<",
            items: "=mnFamilies",
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: FamilyCtrl,
        require: {ngModelController: "ngModel"}
    }

})();